import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TabMenuService {

  private activeTabUpdate = new BehaviorSubject<string>('');
  activeTabInfo = this.activeTabUpdate.asObservable();

  constructor() { }

  setActiveTabUpdate(data: string) {
    this.activeTabUpdate.next(data);
  }
  
}
