import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ArtechService } from 'src/app/services/artech.service';

export interface DialogData {
  name: string;
}

@Component({
  selector: 'distance-popup',
  templateUrl: './distance-popup.component.html',
  styleUrls: ['./distance-popup.component.scss'],
})
export class DistancePopupComponent implements OnInit {
  distanceForm: FormGroup;

  submitted = false;

  public expensesControls: { value: string; comment: string; type: 'food' | 'additional' }[] = [];

  @ViewChild('scrollframe', {static: false}) scrollFrame: ElementRef;

  constructor(
    public artechService: ArtechService,
    public dialogRef: MatDialogRef<DistancePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    console.log(this.data);
    this.distanceForm = new FormGroup({
      price: new FormControl(''),
      km: new FormControl(''),
      expense: new FormControl(''),
      comments: new FormControl(''),
      km_home: new FormControl('')
    });
  }

  ngOnInit() {}

  close() {
    this.dialogRef.close();
  }

  get foodInvalid() {
   return this.expensesControls.filter(item => item.type === 'food').some(value => !value.value);
  }

  get additionalInvalid() {
    return this.expensesControls.filter(item => item.type === 'additional').some(value => !value.value || !value.comment);
  }

  async save() {
    this.submitted = true;

    if (this.distanceForm.invalid || this.foodInvalid || this.additionalInvalid) {
      console.log('NOT VALID');
      return;
    }

    console.log('SAVE :>> ');

    const fundsTMP = this.distanceForm.controls.price.value + ',' + this.getFunds('food');
    const funds = this.splitString(fundsTMP, ',');
    const expenseTMP = this.distanceForm.controls.expense.value + ',' + this.getFunds('additional')[1];
    const expense = this.splitString(expenseTMP, ',');
    const comments = this.distanceForm.controls.comments.value + ',' + this.getFunds('additional')[0];
    try {
      this.artechService.setExpenses(this.artechService.userInfo.id, this.distanceForm.controls.km.value, funds, expense, comments, this.distanceForm.controls.km_home.value, this.data['event_id'], this.data['date'])
      .then(res => {
        console.log('setExpenses :>> ', res);
      });

    } catch (e) {
      console.error(e);
    } finally {
      console.log('finally :>> ');
      this.close();
    }
  }

  splitString(stringToSplit, separator) {
    const arrayOfStrings = stringToSplit.split(separator);
    let sum = 0;
    arrayOfStrings.forEach(element => {
      sum += Number(element);
    });
    return sum;
  }
  getFunds(type: 'food' | 'additional') {
    if (type === 'food') {
      console.log('type food')
      return this.expensesControls.map(value => value.value).join(',');
    } else {
      const comments = this.expensesControls.map(value => value.comment).join(',');
      const values = this.expensesControls.map(value => value.value).join(',');
      return [comments, values];
    }
  }

  add(type: 'food' | 'additional') {
    this.expensesControls.push({value: '', comment: '', type});
  }

  deleteItem(index) {
    this.expensesControls.splice(index, 1);
  }

}
