import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatButtonComponent } from './chat-button.component';
import { MatMenuModule } from '@angular/material/menu';


@NgModule({
  declarations: [ChatButtonComponent],
  imports: [
    CommonModule
  ],
  exports: [
    ChatButtonComponent,
    MatMenuModule,
  ]
})
export class ChatButtonModule { }
