import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'welcome',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'list',
    loadChildren: () => import('./list/list.module').then(m => m.ListPageModule)
  },
  { path: 'tabs', loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule) },
  { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule) },
  { path: 'welcome', loadChildren: () => import('./welcome/welcome.module').then(m => m.WelcomePageModule) },
  // { path: 'tabs/tasks', loadChildren: './tabs/tasks/tasks.module#TasksPageModule' },
  { path: 'tabs/events/:type', loadChildren: () => import('./tabs/events/events.module').then(m => m.EventsPageModule) },
  { path: 'intake', loadChildren: () => import('./intake/intake.module').then(m => m.IntakePageModule) },
  // { path: 'myusers', loadChildren: () => import('./myusers/myusers.module').then(m => m.MyusersPageModule) },
  { path: 'chat', loadChildren: () => import('./chat/chat.module').then(m => m.ChatPageModule) },
  { path: 'task/:taskid/:date', loadChildren: () => import('./tabs/tasks/task/task.module').then(m => m.TaskPageModule) },

  { path: 'cities/:city', loadChildren: () => import('./cities/cities.module').then(m => m.CitiesPageModule) },

  { path: 'event/:eventid/:title', loadChildren: () => import('./tabs/events/event/event.module').then(m => m.EventPageModule) },
  { path: 'event', loadChildren: () => import('./tabs/events/event/event.module').then(m => m.EventPageModule) },


  { path: 'new-event', loadChildren: () => import('./tabs/events/new-event/new-event.module').then(m => m.NewEventModule) },

  // { path: 'tabs/employers', loadChildren: './tabs/employers/employers.module#EmployersPageModule' },
  { path: 'employer/:employerid', loadChildren: () => import('./tabs/employers/employer/employer.module').then(m => m.EmployerPageModule) },
  { path: 'create-user', loadChildren: () => import('./create-user/create-user.module').then(m => m.CreateUserPageModule) },
  { path: 'search-user', loadChildren: () => import('./myusers/search-user/search-user.module').then(m => m.SearchUserPageModule) },
  { path: 'chat-room', loadChildren: () => import('./chat-room/chat-room.module').then(m => m.ChatRoomPageModule) },
  { path: 'update-value', loadChildren: () => import('./update-value/update-value.module').then(m => m.UpdateValuePageModule) },
  { path: 'popover-menu', loadChildren: () => import('./popover-menu/popover-menu.module').then(m => m.PopoverMenuPageModule) },
  // { path: 'tabs/dashboard', loadChildren: './tabs/dashboard/dashboard.module#DashboardPageModule' },

  { path: 'tabs/calendar/details', loadChildren: () => import('./calendar/calendar-details/calendar-details.module').then(m => m.CalendarDetailsPageModule) },
  
  { path: 'processes', loadChildren: () => import('./tabs/processes/processes.module').then(m => m.ProcessesPageModule) },
  { path: 'questionnaire', loadChildren: () => import('./questionnaire/questionnaire.module').then(m => m.QuestionnaireModule) },
  {
    path: 'tabs/calendar/calendar-details',
    loadChildren: () => import('./calendar/calendar-details/calendar-details.module').then( m => m.CalendarDetailsPageModule)
  },
  {
    path: '**',
    redirectTo: 'tabs'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
