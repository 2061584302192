import {Component, OnDestroy} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import {ArtechService} from './services/artech.service';
import {ReplaySubject} from 'rxjs';
import {ReportNotificationComponent} from './shared/components/report-notification/report-notification.component';
import {MatDialog} from '@angular/material/dialog';

import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent implements OnDestroy {
  public destroy$: ReplaySubject<void> = new ReplaySubject<void>();

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public translate: TranslateService,
    public apiService: ArtechService,
    public dialog: MatDialog,
  ) {
    //  this.platform.setDir('rtl', true);
      this.initializeApp();
      // this language will be used as a fallback when a translation isn't found in the current language
      translate.setDefaultLang('he');

      // the lang to use, if the lang isn't available, it will use the current loader to get them
      translate.use('he');
  }

  initializeApp() {
    if (this.platform.is('android')) {
      this.statusBar.overlaysWebView(false);
    } else {
      this.statusBar.overlaysWebView(true);
    }

    setTimeout(()=> {
      this.registerPushIOS();
    }, 10000);
    
    this.platform.ready().then(async () => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  registerPushIOS() {
    PushNotifications.requestPermissions().then(result => {
      if (result.receive === 'granted') {
        PushNotifications.register();
      } else {
        // Show some error
      }
    });

    PushNotifications.addListener('registration', (token: Token) => {
        // alert('Push registration success, token: ' + token.value);
        console.log( 'TOKEN ==>', JSON.stringify(token.value));
        // alert('TOKEN =>' + token.value);
        this.registerToken(token.value);
    });

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError', (error: any) => {
        // alert('Error on registration: ' + JSON.stringify(error));
        console.log('Error on registration: ' + JSON.stringify(error));
    });

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => {
      // alert('Push received: ' + JSON.stringify(notification));
      console.log('Push received: ' + JSON.stringify(notification));
      let showPopUp = false;
      if (!this.apiService.userInfo || this.apiService.userInfo.id === 0) {
         localStorage.setItem('showPushPopup', JSON.stringify(new Date()));
      } else {
        showPopUp = true;
      }
      if (notification && showPopUp) {
        this.dialog.open(ReportNotificationComponent, {
          width: '250px',
          panelClass: 'report-notification'
        });
      }
      if (notification) {
        console.log('Notification received in background');
      } else {
        console.log('Notification received in foreground');
      }
      console.log('Notification payload: ', notification);
    });

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        // alert('Push action performed: ' + JSON.stringify(notification));
        console.log('Push action performed: ' + JSON.stringify(notification));
      }
    );
  }

  registerToken(token: string) {
    this.apiService.registerToken(token);
  }

  ngOnDestroy() {
      this.destroy$.next();
      this.destroy$.complete();
  }
}
