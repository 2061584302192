import { Component, Inject, OnInit } from '@angular/core';
import { ArtechService } from '../services/artech.service';

import { Injectable } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { BehaviorSubject, Observable, of as observableOf } from 'rxjs';
import { FlatTreeControl } from '@angular/cdk/tree';
import { NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


/**
 * File node data with nested structure.
 * Each node has a filename, and a type or a list of children.
 */
export class UserNode {
    children: UserNode[];
    name: string;
    text: string;
    type: any;
    uid: number;
    icon: string;
    code: string;
}

/** Flat node with expandable and level information */
export class UserFlatNode {
    constructor(
        public expandable: boolean, public name: string, public level: number, public type: any) { }
}


/**
 * File database, it can build a tree structured Json object from string.
 * Each node in Json object represents a file or a directory. For a file, it has filename and type.
 * For a directory, it has filename and children (a list of files or directories).
 * The input will be a json object string, and the output is a list of `UserNode` with nested
 * structure.
 */
@Injectable()
export class FileDatabase {
    dataChange = new BehaviorSubject<UserNode[]>([]);
    treeData: any[];
    flatData: any[];
    get data(): UserNode[] { return this.dataChange.value; }

    constructor(public artechServ: ArtechService) {
        this.initialize();
    }

    initialize() {
        // console.log(this.artechServ.myUsers);
        // Parse the string to json object.
        const dataObject = this.artechServ.myUsers;
        this.treeData = this.artechServ.myUsers;

        this.flatData = this.buildFlat(this.artechServ.myUsers);
        //    console.log(this.flatData);
        // Build the tree nodes from Json object. The result is a list of `UserNode` with nested
        //     file node as children.
        const data = this.buildFileTree(dataObject, 0);
        //    console.log(data);
        // Notify the change.
        this.dataChange.next(data);
    }
    buildFlat(obj: { [key: string]: any }) {
        return Object.keys(obj).reduce<UserNode[]>((accumulator, key) => {
            const value = obj[key];
            const node = new UserNode();
            node.name = value.text;
            node.text = value.text;
            node.code = value.code;
            if (value != null) {
                node.type = value;
            }
            if (value.nodes) {
                node.children = this.buildFlat(value.nodes);
                // node.expandable = true;
                return accumulator.concat(node).concat(node.children);
            }
            // console.log(accumulator);
            return accumulator.concat(node);
        }, []);
    }
    /**
     * Build the file structure tree. The `value` is the Json object, or a sub-tree of a Json object.
     * The return value is the list of `UserNode`.
     */
    buildFileTree(obj: { [key: string]: any }, level: number): UserNode[] {
        return Object.keys(obj).reduce<UserNode[]>((accumulator, key) => {
            const value = obj[key];
            const node = new UserNode();
            node.name = value.text;
            node.text = value.text;
            node.code = value.code;
            if (value != null) {
                if (value.nodes) {
                    node.children = this.buildFileTree(value.nodes, level + 1);
                }
                node.type = value;
            }

            return accumulator.concat(node);
        }, []);
    }

    public filter(filterText: string) {
        let filteredTreeData;
        if (filterText) {
            // console.log(filterText);
            console.log(this.flatData);
            filteredTreeData = this.flatData.filter(d => {

                return d.text.toLocaleLowerCase().indexOf(filterText.toLocaleLowerCase()) > -1;
            });

            Object.assign([], filteredTreeData).forEach(ftd => {
                let str = (<string>ftd.code);
                while (str.lastIndexOf('.') > -1) {
                    const index = str.lastIndexOf('.');
                    str = str.substring(0, index);
                    if (filteredTreeData.findIndex(t => t.code === str) === -1) {
                        const obj = this.flatData.find(d => d.code === str);
                        if (obj) {
                            filteredTreeData.push(obj);
                        }
                    }
                }
            });
        } else {
            // filteredTreeData = this.dataObject;
        }

        // Build the tree nodes from Json object. The result is a list of `TodoItemNode` with nested
        // file node as children.
        const data = this.buildFileTree(filteredTreeData, 0);
        console.log(data);
        // Notify the change.
        this.dataChange.next(data);
    }

}

export interface DialogData {
    name: string;
}

/**
 * @title Tree with flat nodes
 */
@Component({
    selector: 'app-myusers',
    templateUrl: './myusers.page.html',
    styleUrls: ['./myusers.page.scss'],
    providers: [FileDatabase]
})
export class MyusersPage implements OnInit {
    searchTerm = this.translate.instant('SEARCH');
    treeControl: FlatTreeControl<UserFlatNode>;
    treeFlattener: MatTreeFlattener<UserNode, UserFlatNode>;
    dataSource: MatTreeFlatDataSource<UserNode, UserFlatNode>;
    public filterText: string;
    //   myusersArr: any[];
    constructor(
        public database: FileDatabase,
        public artechServ: ArtechService,
        public navCtrl: NavController,
        public translate: TranslateService,
        public dialogRef: MatDialogRef<MyusersPage>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {
        this.treeFlattener = new MatTreeFlattener(this.transformer, this._getLevel, this._isExpandable, this._getChildren);
        this.treeControl = new FlatTreeControl<UserFlatNode>(this._getLevel, this._isExpandable);
        this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

        database.dataChange.subscribe(data => {
            this.dataSource.data = data
        });
    }

    transformer = (node: UserNode, level: number) => {
        return new UserFlatNode(!!node.children, node.name, level, node.type);
    }

    private _getLevel = (node: UserFlatNode) => node.level;

    private _isExpandable = (node: UserFlatNode) => node.expandable;

    private _getChildren = (node: UserNode): Observable<UserNode[]> => observableOf(node.children);

    hasChild = (_: number, _nodeData: UserFlatNode) => _nodeData.expandable;

    ngOnInit() {
        console.log()
    }

    public changeUser(uid, type, e) {
        console.log('changeUser :>> ', uid, type);
        // if (!uid) {
        //     uid = type.type.uid;
        // }
        if (uid) {
            if (e) {
                e.stopPropagation();
            }
            this.artechServ.setUser(uid).then(() => {
                this.closeMenu();
                this.navCtrl.navigateRoot('home');
                // this.navCtrl.goBack();
                //  this.navCtrl.navigateRoot('home');
            });
        }
    }

    filterChanged(filterText: string) {
        //   console.log(filterText);

        if (filterText && filterText.length > 2) {
            this.database.filter(filterText);
            console.log(this.database);
            this.treeControl.expandAll();
        } else {
            this.database.initialize();
            this.treeControl.collapseAll();
        }
    }

    backtome() {
        this.changeUser(this.artechServ.conectedUserId, {}, null);
    }

    openPage(page) {
        console.log('openPage :>> ', page);
        this.closeMenu();
        this.navCtrl.navigateForward(page);
    }

    closeMenu() {
        this.dialogRef.close();
    }

}
