import { Component } from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import { ArtechService } from 'src/app/services/artech.service';
import {ReportNotificationManualComponent} from '../report-notification-manual/report-notification-manual.component';

@Component({
    selector: 'report-notification',
    templateUrl: './report-notification.component.html',
    styleUrls: ['./report-notification.component.scss'],
})
export class ReportNotificationComponent {
    public userHours;
    constructor(
        public dialogRef: MatDialogRef<ReportNotificationComponent>,
        private _dialog: MatDialog,
        public artechService: ArtechService
    ) {
        this.getUserHours();
    }

    async getUserHours() {
        this.userHours = await this.artechService.getUserHours();
    }

    close() {
        this.dialogRef.close();
    }

    confirm() {
        if (this.userHours && this.userHours.weekhours) {
            let culcCurrent = Number(this.userHours.current) + 8;
            if (culcCurrent > Number(this.userHours.weekhours)) {
                alert('מגבלת העבודה השבועית חרגה');
            } else {
                this.artechService.saveUserHours('08:00', '16:00');
                localStorage.setItem('showPushPopup', JSON.stringify(new Date()));
                this.artechService.setUpdatePushPopup(true);
                this.close();
            }
        } else {
            this.artechService.saveUserHours('08:00', '16:00');
            localStorage.setItem('showPushPopup', JSON.stringify(new Date()));
            this.artechService.setUpdatePushPopup(true);
            this.close();
        }
    }


    manual() {
        this.close();
        this._dialog.open(ReportNotificationManualComponent, {
            width: '250px',
            panelClass: 'report-notification-manual'
        });
    }

}
