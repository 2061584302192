import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { ArtechService } from 'src/app/services/artech.service';

@Component({
  selector: 'chat-button',
  templateUrl: './chat-button.component.html',
  styleUrls: ['./chat-button.component.scss'],
})
export class ChatButtonComponent implements OnInit {

  constructor(
    public artechService: ArtechService,
    public navCtrl: NavController,
  ) { }

  ngOnInit() { 
    console.log('CHATUSERS :>> ', this.artechService.toArray(this.artechService.chatUsers));
  }

  openChat(archatid, name) {
    if (archatid) {
      this.navCtrl.navigateForward('chat-room/' + archatid + '/' + name);
    }
  }

}
