import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { MyusersPage } from './myusers.page';
import { MatIconModule } from '@angular/material/icon';
import { MatTreeModule } from '@angular/material/tree';
import { TranslateModule } from '@ngx-translate/core';
import { ChatButtonModule } from '../components/chat-button/chat-button.module';

@NgModule({
  imports: [
    MatTreeModule,
    MatIconModule,
    CommonModule,
    FormsModule,
    IonicModule,
    TranslateModule,
    ChatButtonModule
  ],
  declarations: [MyusersPage]
})
export class MyusersPageModule { }
