import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { HttpClient, HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { MaterialModule } from './material-module';
// import { MatSortModule } from '@angular/material/sort';
// import { CdkTableModule } from '@angular/cdk/table';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { FilterPipe } from './filter.pipe';
import firebase from 'firebase/app';
// import "firebase/firestore";

import 'firebase/auth';
import 'firebase/database';
import { UpdateValuePage } from './update-value/update-value.page';
import { UpdateValuePageModule } from './update-value/update-value.module';
import { PopoverMenuPageModule } from './popover-menu/popover-menu.module';
import { HeaderModule } from './components/header/header.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {ReportNotificationComponent} from './shared/components/report-notification/report-notification.component';
import {ReportNotificationManualComponent} from './shared/components/report-notification-manual/report-notification-manual.component';
import {DistancePopupComponent} from './components/distance-popup/distance-popup.component';
import {MatButtonModule} from '@angular/material/button';


const config = {
    apiKey: 'AIzaSyAC4a44cj67sQhAUnJ5kL4qkkSDmdFl4J4',
    authDomain: 'archat2-84ce0.firebaseapp.com',
    databaseURL: 'https://archat2-84ce0.firebaseio.com',
    projectId: 'archat2-84ce0',
    storageBucket: 'archat2-84ce0.appspot.com',
    messagingSenderId: '759352943959'
};
firebase.initializeApp(config);
export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [
        AppComponent,
        FilterPipe,
        ReportNotificationComponent,
        ReportNotificationManualComponent,
        DistancePopupComponent
    ],
    entryComponents: [
        UpdateValuePage
    ],
    imports: [
        BrowserModule,
        HeaderModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        HttpClientModule,
        HttpClientJsonpModule,
        MaterialModule,
        UpdateValuePageModule,
        PopoverMenuPageModule,
        ReactiveFormsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        BrowserAnimationsModule,
        FormsModule,
        MatButtonModule,

    ],
    providers: [
        StatusBar,
        SplashScreen,
        NativeStorage,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
    ],

    bootstrap: [AppComponent]
})
export class AppModule { }
