import {Injectable} from '@angular/core';
import 'rxjs/add/operator/map';
import {HttpApiService} from './http-api.service';
import firebase from 'firebase/app';
import 'firebase/auth';
import { BehaviorSubject } from 'rxjs';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { NavController, Platform } from '@ionic/angular';
import { Http, HttpResponse } from '@capacitor-community/http';

const BASE_URL = 'https://artech.org.il';

export class UserInfo {
    id = 0;
    name = '';
}

@Injectable({
    providedIn: 'root'
})
export class ArtechService {
    public id = 0;
    public conectedUserId = 0;
    public userInfo: UserInfo = {
        id: 0,
        name: ''
    };
    public myUserInfo: UserInfo = {
        id: 0,
        name: ''
    };
    public myUsers: any = [];
    public chatUsers: any = [];
    public userEvents: any[];
    public userIntake: any[];
    public userTasks: any[];
    public userFiles: any[];
    public firebsaeData: any;
    private taskForm: any[];
    private eventForm: any[];
    private employerForm: any[];
    public userDashboard: any;
    public categories: any[];
    public projects: any[];
    public cities: any[];
    public userEmployers: any[];
    public mainUser;
    public pushToken;

    private updateCalendar = new BehaviorSubject<any>(null);
    public onUpdateCalendar = this.updateCalendar.asObservable();

    private updateReportUser = new BehaviorSubject<any>(null);
    public onUpdateReportUser = this.updateReportUser.asObservable();

    private updateMonthlyReport = new BehaviorSubject<any>(null);
    public onUpdateMonthlyReport = this.updateMonthlyReport.asObservable();

    private getUserInfo = new BehaviorSubject<any>(null);
    public onGetUserInfo = this.getUserInfo.asObservable();

    private updateFilesUser = new BehaviorSubject<any>(null);
    public onUpdateFilesUser = this.updateFilesUser.asObservable();

    private updatePushPopup = new BehaviorSubject<any>(null);
    public onUpdatePushPopup = this.updatePushPopup.asObservable();

    private updateEventId = new BehaviorSubject<any>(null);
    public onUpdateEventId = this.updateEventId.asObservable();

    private updateTaskId = new BehaviorSubject<any>(null);
    public onUpdateTaskId = this.updateTaskId.asObservable();

    constructor(
        private httpApi: HttpApiService,
        private platform: Platform,
        private storage: NativeStorage, private navCtrl: NavController) {
        this.constructorFunc();
    }

    async constructorFunc() {
        if (this.userInfo.id === 0 && localStorage.getItem('mainUser')) {
            this.userInfo = JSON.parse(localStorage.getItem('mainUser'));
            this.mainUser = JSON.parse(localStorage.getItem('mainUser'));
        }
        await this.getUser();
        if (this.userInfo && this.userInfo.id > 0) {
            this.conectedUserId = this.userInfo.id;
            this.changeUser();
            this.start();
        } else if (this.userInfo.id === 0) {
            this.logout();
            this.navCtrl.navigateRoot('login');
        }
    }


    setUpdateTask(data) {
        this.updateTaskId.next(data);
    }

    setUpdateEvent(data) {
        this.updateEventId.next(data);
    }

    setUpdateCalendar(data) {
        this.updateCalendar.next(data);
    }

    setUpdateReportUser(data) {
        this.updateReportUser.next(data);
    }

    setUpdateMonthlyReport(data) {
        this.updateMonthlyReport.next(data);
    }

    setUserInfo(data) {
        this.getUserInfo.next(data);
    }

    setUpdateFilesUser(data) {
        this.updateFilesUser.next(data);
    }

    setUpdatePushPopup(data) {
        this.updatePushPopup.next(data);
    }

    start() {
        this.getCategories();
        this.getProjects();
        this.getTasksForm();
        this.getEmployerForm();
    }

    async changeUser() {
        await this.getUser();
        if (this.userInfo && this.userInfo.id > 0) {
            this.getUsers();
            this.getChatUsers();
            this.getFirebase();
        }
    }

    login(username, password) {
        const data = {
            option: 'com_artech_users',
            task: 'api',
            func: 'login',
            api: 1,
            username: username,
            password: password
        };
        return new Promise( async (resolve) => {
            this.httpApi.get('ARTECH', data, {}, false).then((res: any) => {
                localStorage.setItem('mainUser', JSON.stringify(res.data));
                this.storage.setItem('mainUser', res.data);
                this.userInfo = JSON.parse(JSON.stringify(res.data));
                this.mainUser = res.data;
                this.setUserInfo(JSON.parse(JSON.stringify(res.data)));
                this.changeUser();
                this.start();
                this.conectedUserId = this.userInfo.id;
                resolve(res);
            }).catch((error: any) => {
                if (error) {
                    // console.error(error)
                    // alert("Login error" + error)
                }
            });
        });
    }

    saveUserHours(start, end) {
        const data = {
            option: 'com_artech_timekeeper',
            task: 'api',
            func: 'adddaily',
            start: start,
            end: end
        };
        return new Promise(resolve => {
            this.httpApi.get('ARTECH', data, {}, false).then((res: any) => {
                resolve(res);
            });
        });
    }

    getUserHours() {
        const data = {
            option: 'com_artech_timekeeper',
            task: 'api',
            func: 'getmaxhoures'
        };
        return new Promise(resolve => {
            this.httpApi.get('ARTECH', data, {}, false).then((res: any) => {
                resolve(res.value);
            });
        });
    }

    getUser() {
        const data = {
            option: 'com_artech_users',
            task: 'api',
            func: 'getuser',
            user: this.userInfo.id,
            api: 1
        };
        return new Promise(resolve => {
            this.httpApi.get('ARTECH', data, {}, false).then((res: any) => {
                if (res && res.status !== 0) {
                    this.userInfo = res.data;
                    this.conectedUserId = res.data.id;
                    this.myUserInfo = res.data;
                    this.setUserInfo(this.userInfo);
                    this.getEvents(res.data.id);
                    this.getTasks(res.data.id);
                    this.getIntake(res.data.id);
                    this.getDashboard(res.data.id);
                    this.getEmployers(res.data.id);
                    this.getFiles(res.data.id);
                    resolve(res.data);
                }
            });
        });
    }

    getWorkingHours(month) {
        const data = {
            option: 'com_artech_timekeeper',
            task: 'api',
            func: 'getreport',
            api: 1,
            month
        };
        return new Promise(resolve => {
            this.httpApi.get('ARTECH', data, {}, false).then((res: any) => {
                resolve(res.value);
            });
        });
    }


    mainUserOrNot() {
        if (this.mainUser && this.mainUser.id && this.userInfo && this.userInfo.id) {
            return this.mainUser.id !== this.userInfo.id;
        } else {
            return false;
        }
    }

    getUserName() {
        return this.userInfo ? this.userInfo.name : '';
    }

    logout() {
        const data = {
            option: 'com_artech_users',
            task: 'api',
            func: 'logout',
            api: 1
        };
        return new Promise(resolve => {
            this.httpApi.get('ARTECH', data, {}, false).then((res: UserInfo) => {
                this.userInfo = res;
                localStorage.removeItem('mainUser');
                this.storage.remove('mainUser');
                firebase.auth().signOut().then(() => {
                    resolve(res);
                });
            });
        });
    }


    getUsers(): any {
        const data = {
            option: 'com_artech_users',
            task: 'api',
            func: 'myusers',
            api: 1
        };
        return new Promise(resolve => {
            this.httpApi.get('ARTECH', data, {}, false).then((res: { data: any }) => {
                this.myUsers = res.data;
                resolve(res);
            });
        });
    }

    getChatUsers() {
        const data = {
            option: 'com_artech_users',
            task: 'api',
            func: 'getChatUsers',
            api: 1
        };
        return new Promise(resolve => {
            this.httpApi.get('ARTECH', data, {}, false).then((res: { data: any }) => {
                this.chatUsers = res.data;
                resolve(res);
            });
        });

    }

    getMyUsers() {
        const data = {
            option: 'com_artech_users',
            task: 'api',
            func: 'myusers_list',
            api: 1
        };

        return new Promise(resolve => {
            this.httpApi.get('ARTECH', data, {}, false).then((res: {data: any}) => {
                resolve(res.data);
            });
        });
    }

    getFirebase() {
        const data = {
            option: 'com_artech_users',
            task: 'api',
            func: 'getUserFirebase',
            api: 1
        };
        return new Promise(resolve => {
            this.httpApi.get('ARTECH', data, {}, false).then((res: any) => {
                console.log(res);
                this.firebsaeData = res.data;
                firebase.auth().signInWithCustomToken(this.firebsaeData.fbtoken).then(user => {
                    resolve(res);
                }).catch(reason => {
                    resolve(reason);
                });
            });
        });
    }

    setUser(uid) {
        const data = {
            option: 'com_artech_users',
            task: 'api',
            func: 'getuser',
            user: uid,
            api: 1
        };
        return new Promise(resolve => {
            this.httpApi.get('ARTECH', data, {}, false).then((res: any) => {
                this.userInfo = res.data;
                this.setUserInfo(this.userInfo);
                this.getEvents(res.data.id);
                this.getTasks(res.data.id);
                this.getIntake(res.data.id);
                this.getDashboard(res.data.id);
                this.getEmployers(res.data.id);
                resolve(res.data);
            });
        });
    }

    getEvents(uid) {
        console.log('getEvents');
        this.userEvents = [];
        const data = {
            option: 'com_btla_tracing',
            task: 'jsonp',
            my: uid,
            limit: 20000,
            lang: 'heb',
            api: 1
        };
        return new Promise(resolve => {
            this.httpApi.get('ARTECH', data, {}, false).then((res: any) => {
                if (res && res.length > 0) {
                    this.userEvents = res.reverse();
                    this.getEventsForm();
                    resolve(res.data);
                }
            });
        });
    }

    getEventsPromice() {
        if (this.userEvents && this.userEvents.length > 0) {
            return new Promise(resolve => {
                resolve(this.userEvents);
            });
        } else {
            const data = {
                option: 'com_btla_tracing',
                task: 'jsonp',
                my: this.userInfo.id,
                limit: 20000,
                lang: 'heb',
                api: 1
            };
            return new Promise(resolve => {
                this.httpApi.get('ARTECH', data, {}, false).then((res: any) => {
                    if (res ) {
                        console.log('res', res);
                        this.userEvents = res.reverse();
                        resolve(this.userEvents);
                    } else {
                        resolve([]);
                    }
                });
            });
        }
    }

    getFiles(uid = this.userInfo.id) {
        const data = {
            option: 'com_btla_files',
            task: 'api',
            my: uid,
            api: 1,
            func: 'getList'
        };
        if (!this.userInfo || this.userInfo.id === 0) {
            return;
        }

        return new Promise(resolve => {
            this.httpApi.get('ARTECH', data, {}, false).then((res: any) => {
                if (res && res.data.length > 0) {
                    const arrFiles = res.data;
                    this.userFiles = arrFiles;
                    resolve(res.data);
                }
            });
        });
    }

    getEmployers(uid) {
        const data = {
            option: 'com_btla_boss',
            task: 'jsonp',
            my: uid,
            limit: 20000,
            lang: 'heb',
            api: 1
        };
        return new Promise(resolve => {
            this.httpApi.get('ARTECH', data, {}, false).then((res: any) => {
                if (res && res.length > 0) {
                    this.userEmployers = res.reverse();
                }
            });
        });
    }

    getTasks(uid) {
        const data = {
            option: 'com_btla_tasks',
            task: 'jsonp',
            my: uid,
            limit: 20000,
            lang: 'heb',
            api: 1
        };
        return new Promise(resolve => {
            this.httpApi.get('ARTECH', data, {}, false).then((res: any) => {
                if (res && res.length > 0) {
                    this.userTasks = res.reverse();
                    resolve(res);
                }
            });
        });
    }

    getTasksPromice() {
        if (this.userTasks && this.userTasks.length > 0) {
            return new Promise(resolve => {
                resolve(this.userTasks);
            });
        } else {
            const data = {
                option: 'com_btla_tasks',
                task: 'jsonp',
                my: this.userInfo.id,
                limit: 20000,
                lang: 'heb',
                api: 1
            };
            return new Promise(resolve => {
                this.httpApi.get('ARTECH', data, {}, false).then((res: any) => {
                    if (res && res.length > 0) {
                        this.userTasks = res.reverse();
                        resolve(this.userTasks);
                    } else {
                        resolve([]);
                    }
                });
            });
        }
    }



    getTasksForm() {
        const data = {
            option: 'com_btla_tasks',
            task: 'jsonp',
            func: 'getform',
            api: 1
        };
        return new Promise(resolve => {
            this.httpApi.get('ARTECH', data, {}, false).then((res: any) => {
                this.taskForm = res;
            });
        });
    }

    getEmployerForm() {
        const data = {
            option: 'com_btla_boss',
            task: 'jsonp',
            func: 'getform',
            api: 1
        };
        this.httpApi.get('ARTECH', data, {}, false).then((res: any) => {
            this.employerForm = res;
        });
    }

    getEventsForm() {
        const data = {
            option: 'com_btla_tracing',
            task: 'jsonp',
            func: 'getform',
            api: 1
        };
        return new Promise(resolve => {
            this.httpApi.get('ARTECH', data, {}, false).then((res: any) => {
                this.eventForm = res;
            });
        });
    }

    getIntake(uid) {
        const data = {
            option: 'com_btla_intec',
            task: 'jsonp',
            myUserId: uid,
            limit: 20000,
            lang: 'heb',
            api: 1
        };
        return new Promise(resolve => {
            this.httpApi.get('ARTECH', data, {}, false).then((res: any) => {
                this.userIntake = res;
                console.log('userIntake ==>', this.userIntake);
                // this.userIntake.forEach(field => {
                //     // console.log(field);
                //     if (field.id === '7') {
                //         //  console.log(field.options);
                //         this.cities = field.options;
                //     }
                //     // console.log( this.cities);
                //     if (field.ansewr == null) {
                //         field.isnew = true;
                //     }
                //     // console.log( this.cities);
                // });
                resolve(res);
            });
        });
    }

    getDashboard(uid) {
        const data = {
            option: 'com_artech_dashboard',
            task: 'jsonp',
            my: uid,
            limit: 20000,
            lang: 'heb',
            api: 1
        };
        return new Promise(resolve => {
            this.httpApi.get('ARTECH', data, {}, false).then((res: any) => {
                this.userDashboard = res;
                resolve(res);
            });
        });
    }

    getProjects() {
        const data = {
            option: 'com_artech_users',
            task: 'api',
            func: 'getprojects',
            api: 1
        };
        this.httpApi.get('ARTECH', data, {}, false).then((res: any) => {
            this.projects = res.data;
        });
    }

    getCategories() {
        const data = {
            option: 'com_artech_users',
            task: 'api',
            func: 'getcategories',
            api: 1
        };
        this.httpApi.get('ARTECH', data, {}, false).then((res: any) => {
            this.categories = res.data;
            //  resolve(res.data);
        });
    }

    getCategoryById(catid) {
        this.categories.forEach(value => {
            if (parseInt(value.id) === catid) {
                return value;
            }
        });
    }

    getCategorisByExtension(extension) {
        const temp = [];
        if (this.categories) {
            this.categories.forEach(value => {
                if (value.extension === extension) {
                    temp.push({value: value.id.toString(), title: value.title});
                }
            });
        }
        return temp;
    }

    getReports(): Promise<any> {
        const data = {
            option: 'com_artech_users',
            task: 'api',
            func: 'getMonthlyReport',
            api: 1
        };

        return this.httpApi.get('ARTECH', data, {}, true);
    }

    getTask(taskId) {
        const task = this.getTaskById(taskId);
        if (!task) {
            return;
        }
        const thetask = [
            //     {title: 'id', value: this.userTasks[taskId].id, type: 'static' },
            {title: 'title', value: task.title, type: 'text'},
            {title: 'time', value: task.time, type: 'select', options: this.taskForm['time']},
            {title: 'purpose', value: task.purpose, type: 'select', options: this.taskForm['purpose']},
            {
                title: 'category', value: task.category,
                type: 'select', options: this.getCategorisByExtension('com_btla_tasks')
            },
            {title: 'another', value: task.another, type: 'text'},
            {title: 'importance', value: task.importance, type: 'select', options: this.taskForm['importance']},
            {title: 'alert', value: task.alert, type: 'calendar'},
            {title: 'done', value: task.done, type: 'range'},
            {title: 'details', value: task.details, type: 'textarea'},
            {title: 'comments', value: task.comments, type: 'textarea'},
            {title: 'created', value: task.created, type: 'calendar'}

        ];

        return thetask;
    }

    getTaskById(taskId) {
        if (this.userTasks && this.userTasks.length > 0) {
            return this.userTasks.filter(task => task.id === taskId)[0];
        } else {
            this.getTasks(this.userInfo.id).then(() => {
                return this.userTasks.filter(task => task.id === taskId)[0];
            });
        }
    }

    getEvent(eventId) {
        const event = this.getEventById(eventId);
        if (!event) {
            return undefined;
        }

        return [
            //     {title: 'id', value: this.userTasks[taskId].id, type: 'static' },
            {title: 'title', value: event.title, type: 'text'},
            {title: 'guests', value: event.guests, type: 'text'},
            {
                title: 'type', value: event.type_id, type: 'select',
                options: this.getCategorisByExtension('com_btla_tracing')
            },
            {title: 'date', value: event.date, type: 'calendar'},
            {title: 'update', value: event.update, type: 'calendar'},
            {title: 'comments', value: event.comments, type: 'textarea'},
            {title: 'location', value: event.location, type: 'textarea'},
            {title: 'created', value: event.created, type: 'calendar'}
        ];
    }

    getEmployer(employerId) {
        const employer = this.getEmployerById(employerId);
        const theemployer = [
            //     {title: 'id', value: this.userTasks[taskId].id, type: 'static' },
            {title: 'name', value: employer.name, type: 'text'},
            {title: 'phone', value: employer.phone, type: 'text'},
            {title: 'practice_type', value: employer.practice_type, type: 'text'},
            {
                title: 'field_of_work', value: employer.field_of_work, type: 'select',
                options: this.employerForm['field_of_work']
            },
            {
                title: 'works_scope', value: employer.works_scope, type: 'select',
                options: this.employerForm['works_scope']
            },

            {title: 'role', value: employer.role, type: 'text'},
            {title: 'area_q', value: employer.area_q, type: 'checkbox'},
            {title: 'start_date', value: employer.start_date, type: 'calendar'},
            {title: 'finish_date', value: employer.finish_date, type: 'calendar'},

            {
                title: 'reason', value: employer.reason, type: 'select',
                options: this.employerForm['reason']
            },


            {title: 'comments', value: employer.comments, type: 'textarea'},


        ];

        return theemployer;
    }

    getEmployerById(employerId) {
        return this.userEmployers.filter(employer => employer.id === employerId)[0];
    }

    getEventById(eventId) {
        return (this.userEvents || []).filter(event => event.id === eventId)[0];
    }

    updateIntake(user, qid, answer, isnew, update = false) {
        const data = {
            option: 'com_btla_intec',
            view: 'intec_fields',
            layout: 'updapp',
            api: 1,
            qid: qid,
            answer: answer,
            isnew: isnew,
            userid: user
        };
        this.httpApi.get('ARTECH', data, {}, true).then((res: any) => {
            //   this.categories = res.data;
            if (update) {
                this.getDashboard(this.userInfo.id);
            }
            //  this.getIntake(this.userInfo.id);
        });

    }

    updateEmployer(user, eid, field, value) {
        const data = {
            option: 'com_btla_boss',
            task: 'updatefieldapp',
            my: user,
            api: 1,
            field: field,
            value: value,
            id: eid
        };
        this.httpApi.get('ARTECH', data, {}, false).then((res: any) => {
            const index = this.userEmployers.findIndex((i => i.id === data.id));
            this.userEmployers[index][data.field] = data.value;
            // this.userEmployers = res.data.employers;
            //    this.userEvents = res.data;
            //  resolve(res.data);
        });
    }

    saveReport(userId, days, summary) {
        const data = {
            option: 'com_artech_users',
            task: 'api',
            func: 'addmonthlyreport',
            my: userId,
            api: 1,
            days: days,
            summary: summary
        };
        this.httpApi.get('ARTECH', data, {}, false).then((res: any) => {
            console.log('saveReport', res);
        });
    }

    updateEvent(user, eventid, field, value) {
        const data = {
            option: 'com_btla_tracing',
            task: 'updatefieldapp',
            my: user,
            api: 1,
            field: field,
            value: value,
            id: eventid
        };

        this.httpApi.get('ARTECH', data, {}, false).then((res: any) => {
            const index = this.userEvents.findIndex((i => i.id === eventid));
            this.userEvents[index][data.field] = data.value;
            this.setUpdateEvent(this.userEvents[index]);
            this.setUpdateCalendar(this.userEvents[index]);
        });
    }

    updateTask(user, taskid, field, value) {
        const data = {
            option: 'com_btla_tasks',
            task: 'updatefieldapp',
            my: user,
            api: 1,
            field: field,
            value: value,
            id: taskid
        };

        return new Promise(resolve => {
            this.httpApi.get('ARTECH', data, {}, false).then((res: any) => {
                const index = this.userTasks.findIndex((i => i.id === data.id));
                this.userTasks[index][data.field] = data.value;
                this.setUpdateTask(this.userTasks[index]);
                this.setUpdateCalendar(this.userTasks[index]);
            });
        });
    }

    createEmployer(user) {
        const data = {
            option: 'com_btla_boss',
            task: 'createapp',
            my: user,
            api: 1,
        };
        return new Promise(resolve => {
            this.httpApi.get('ARTECH', data, {}, false).then((res: any) => {
                this.userEmployers = res.data.employers.reverse();
                resolve(res.data.newitem);
            });
        });
    }

    createTask(user) {
        const data = {
            option: 'com_btla_tasks',
            task: 'createapp',
            my: user,
            api: 1,
        };
        return new Promise(resolve => {
            this.httpApi.get('ARTECH', data, {}, false).then((res: any) => {
                if (res) {
                    this.userTasks = res.data.tasks.reverse();
                    resolve(res.data.newitem);
                }
            });
        });
    }

    createEvent(user, link) {
        const data = {
            option: 'com_btla_tracing',
            task: 'createapp',
            link: link,
            my: user,
            api: 1,
        };
        return new Promise(resolve => {
            this.httpApi.get('ARTECH', data, {}, false).then((res: any) => {
                this.userEvents = res.data.events;
                resolve(res.data.newitem);
            });
        });
    }

    checkuser(field, term) {
        const data = {
            option: 'com_artech_users',
            task: 'api',
            func: 'checkuser',
            field: field,
            term: term,
            api: 1
        };
        return new Promise(resolve => {
            this.httpApi.get('ARTECH', data, {}, false).then((res: any) => {
                resolve(res.data);
            });
        });
    }

    createUser(userForm): any {
        const data = {
            option: 'com_artech_users',
            task: 'api',
            func: 'createuser',
            username: userForm.username,
            email: userForm.email,
            project: userForm.project,
            mentor: this.conectedUserId,
            api: 1
        };

        return new Promise(resolve => {
            this.httpApi.get('ARTECH', data, {}, false).then((res: any) => {
                resolve(res.data);
            });
        });
    }

    toArray(rawData: object) {
        const dataValues = [];
        Object.keys(rawData).forEach(function (key) {
            dataValues.push(rawData[key]);
        });
        return dataValues;
    }

    setExpenses(user, kilometers, price, expense, comments, km_home, event_id, date) {
        const data = {
            option: 'com_artech_timekeeper',
            task: 'api',
            func: 'add',
            my: user,
            km: kilometers,
            price: price,
            expense,
            comments,
            api: 1,
            date: new Date(date),
            km_home,
            event_id
        };
        return new Promise(resolve => {
            this.httpApi.get('ARTECH', data, {}, false).then((res: any) => {
                resolve(res);
            });
        });
    }

    forgotPassword(email) {
        const data = {
            option: 'com_artech_users',
            task: 'api',
            func: 'reset',
            email
        };
        return new Promise(resolve => {
            this.httpApi.get('ARTECH', data, {}, false).then((res: any) => {
                resolve(res);
            });
        });
    }

    sendChatFile(file) {
        const data = {
            option: 'com_btla_files',
            task: 'api',
            func: 'getUpload',
        };

        const url = this.getUrlParams(data);
        const formData: FormData = new FormData();
        formData.append('file', file);

        return this.httpApi.post(url, formData);
    }

    sendFile(file, details?) {
        const data = {
            option: 'com_btla_files',
            task: 'api',
            my: this.userInfo.id,
            func: 'appUpload',
            private: 1,
            details: details ? details : ''
        };
        const url = this.getUrlParams(data);
        const formData: FormData = new FormData();
        formData.append('file', file);

        return this.httpApi.post(url.slice(0, -1), formData);
    }

    sendUserFile(file, details, userid, filename = 'filename.png') {
        const data = {
            option: 'com_btla_files',
            task: 'api',
            my: userid,
            func: 'addfile',
            private: 0,
            details,
            filename
        };
        const url = this.getUrlParams(data);
        const formData: FormData = new FormData();
        formData.append('file', file);

        return this.httpApi.post(url, formData).subscribe((v: any) => {
            // alert("addfile"+JSON.stringify(v));
        });
    }

    getUrlParams(data): string {
        let url = '';
        Object.keys(data).forEach(key => {
            url += key + '=' + data[key] + '&';
        });

        return url;
    }

    registerToken(token?, showAlert?) {
        if (token) {
            this.pushToken = token;
        } else if (!token && this.pushToken) {
            token = this.pushToken;
        }
        const data = {
            option: 'com_artech_users',
            task: 'api',
            func: 'register_token',
            system: '',
            model: 'push',
            token
        };
        return new Promise(resolve => {
            this.httpApi.get('ARTECH', data, {}, false).then((res: any) => {
                resolve(res);
            });
        });
    }

    isIphone() {
        if (this.platform.is('iphone')) {
            return true;
        } else {
            return false;
        }
    }

    async isIphoneX() {
        const ratio = window.devicePixelRatio || 1;
        const screen = {
          width : window.screen.width * ratio,
          height : window.screen.height * ratio
        };
        if (this.platform.is('iphone') && screen.width >= 1125 && screen.height >= 2436) {
            return true;
        }
        return false;
    }
}
