import { Component, OnInit } from '@angular/core';
import {ModalController, NavParams, PopoverController} from '@ionic/angular';
import {ArtechService} from '../services/artech.service';

@Component({
  selector: 'app-update-value',
  templateUrl: './update-value.page.html',
  styleUrls: ['./update-value.page.scss'],
})
export class UpdateValuePage implements OnInit {

  userid: number;
  type: string;
  isnew: boolean;
  title: string;
  fieldid: number;
  answer: string = Date();

  constructor( private modalController: ModalController,
               public artechServ: ArtechService,
               private popoverController: PopoverController,
               private navParams: NavParams) { }

  ngOnInit() {
    this.userid = this.navParams.data.userid;
    this.title = this.navParams.data.title;
    this.type = this.navParams.data.field;
    this.fieldid = this.navParams.data.fieldid;
  }

  updateDate(qid, answer, isnew) {
    const date = new Date(this.answer);
    const day = ( '0' + date.getDate()).slice(-2);
    const monthIndex = ( '0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();

    const ans =  day + '-' + monthIndex + '-' + year;
    this.update(qid, ans, isnew);

  }

  update(qid, answer, isnew) {
    this.artechServ.updateIntake(this.userid, qid, answer, isnew);
    setTimeout(() => {this.dismissClick();}, 3000);
  }

  async dismissClick() {
      await this.popoverController.dismiss();
  }
}
