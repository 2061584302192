import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AlertController, LoadingController, ToastController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {catchError} from 'rxjs/operators';
import { Router } from '@angular/router';
import { NativeStorage } from '@ionic-native/native-storage/ngx';


const BASE_URL = 'https://artech.org.il';

@Injectable({
    providedIn: 'root'
})

export class HttpApiService {
  constructor(public http: HttpClient,
              public loadingCtrl: LoadingController,
              public alertCtrl: AlertController,
              public translate: TranslateService,
              public toastController: ToastController,
              private router: Router,
              private storage: NativeStorage
  ) { }


    async presentMsg(msg, status) {
        // const alert = await this.toastController.create({
        //     message: msg,
        //     color: status === 1 ? 'success' : 'danger',
        //     duration: 1000
        // });

        // await alert.present();
    }

    public post(url, data) {
        return this.http.post(BASE_URL + '/index.php?' + url, data).pipe(catchError(async (err) => this.error(err)));
    }

    public get(server, data= {}, dataArray= {}, show_loader) {
        let url = '';
        Object.keys(data).forEach(key => {
            url += key + '=' + data[key] + '&';
        });
        Object.keys(dataArray).forEach(key => {
            url += 'data[' + key + ']=' + dataArray[key] + '&';
        });
        return new Promise(resolve => {
            if (data['my'] === 0 || data['user'] === 0) {
                this.storage.remove('user');
                this.storage.clear();
                this.deleteAllCookies();
                this.router.navigateByUrl('/login');
                resolve({status: 0})
            } else {
                this.http.jsonp(BASE_URL + '/index.php?' + url, 'callback').subscribe((res: any) => {
                    if (res.message) {
                        this.presentMsg(res.message, res.status);
                    }
                    if (res.status !== 0) {
                        resolve(res);
                    }
                },
                error => {
                    if (show_loader) {
                        //  loading.dismiss().catch(() => {});
                    }
                    this.error(error);
                    // alert('api error' + JSON.stringify(error))
                });
            }
        });
    }

    public async error(error) {
        console.log('ERROR =>', error);
        const alert = await this.alertCtrl.create({
            //  title: this.translateW["ERROR_ADDRESS"],
            message: this.translate.instant('NO_SERVICE'),
            buttons: [{
                text: this.translate.instant('CANCEL'),
                role: 'cancel'
            }]
        });
        // alert.present();
    }

    public deleteAllCookies() {
        document.cookie.split(';').forEach(function (c) {
            document.cookie = c.trim().split('=')[0] + '=;' + 'expires=Thu, 01 Jan 1970 00:00:00 UTC;';
        });
    }

}
