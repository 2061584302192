import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ArtechService } from 'src/app/services/artech.service';

@Component({
    selector: 'report-notification-manual',
    templateUrl: './report-notification-manual.component.html',
    styleUrls: ['./report-notification-manual.component.scss'],
})
export class ReportNotificationManualComponent {
    public hours = {
        start: '08:00',
        end: '16:00'
    };
    public userHours;
    constructor(
        public dialogRef: MatDialogRef<ReportNotificationManualComponent>,
        public artechService: ArtechService,

    ) {
        this.getUserHours();
    }

    async getUserHours() {
        this.userHours = await this.artechService.getUserHours();
    }

    close() {
        this.dialogRef.close();
    }

    save() {
        let firstDate = this.hours.start;
        let secondDate = this.hours.end;
        let different = (Number(this.getDate(secondDate)) - Number(this.getDate(firstDate)));
        let hours = Math.floor((different % 86400000) / 3600000);
        let minutes = Math.round(((different % 86400000) % 3600000) / 60000);
        if (this.userHours && this.userHours.weekhours && this.userHours.current) {
            let currentTime = this.getMinutesFromHours(Number(this.userHours.current), 0) + this.getMinutesFromHours(hours, minutes)
            let weekTime = this.getMinutesFromHours(Number(this.userHours.weekhours), 0);
            if (currentTime > weekTime) {
                alert('מגבלת העבודה השבועית חרגה');
            } else {
                this.artechService.saveUserHours(this.hours.start, this.hours.end);
                localStorage.setItem('showPushPopup', JSON.stringify(new Date()));
                this.artechService.setUpdatePushPopup(true);
                this.dialogRef.close();
            }
        } else {
            this.artechService.saveUserHours(this.hours.start, this.hours.end);
            localStorage.setItem('showPushPopup', JSON.stringify(new Date()));
            this.artechService.setUpdatePushPopup(true);
            this.dialogRef.close();
        }
    }

    getMinutesFromHours(hours, minutes) {
        return Number(minutes) + (hours * 60)
    }

    getTimeFromMins(mins) {
        let hours = Math.trunc(mins/60);
        let minutes = mins % 60;
        return hours + ':' + minutes;
    };

    getDate(string) {
        return new Date(0, 0, 0, string.split(':')[0], string.split(':')[1]);
    }
}
