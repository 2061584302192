import { Component, OnInit } from '@angular/core';
import { NavController, NavParams, PopoverController} from '@ionic/angular';
import {ArtechService} from '../services/artech.service';
import {UpdateValuePage} from '../update-value/update-value.page';
import {TranslateService} from '@ngx-translate/core';

class ViewController {
}

@Component({
  selector: 'app-popover-menu',
  templateUrl: './popover-menu.page.html',
  styleUrls: ['./popover-menu.page.scss'],
})
export class PopoverMenuPage implements OnInit {
  menu: any[];
  row;
  pop;
  constructor(
      public artechServ: ArtechService,
      private navCtrl: NavController,
      public popoverController: PopoverController,
      public translate: TranslateService,
      private navParams: NavParams
    ) {}

  ngOnInit() {
    this.menu = this.navParams.data.menu;
    this.row = this.navParams.data.row;
  }

  fireAction(action) {
    this.dismissClick();
    switch (action) {
      case 'changeUser':
        this.artechServ.setUser(this.row.id).then(() => {
          this.navCtrl.navigateRoot('tabs/dashboard');
        });
        break;
      case 'updateValue':
        this.presentPopover(this.row);
        break;
      case 'resetStatus':
        this.artechServ.updateIntake(this.row.id, 228, 0, false, true);
        break;
      case 'sentToManager':
        this.artechServ.updateIntake(this.row.id, 228, 1, true, true);
        break;
      case 'requestSent':
        this.artechServ.updateIntake(this.row.id, 228, 2, false, true);
        break;
    }
  }

  async presentPopover(row: any) {
    event.stopPropagation();
    const popover = await this.popoverController.create({
      component: UpdateValuePage,
      componentProps: {
        userid: row.id,
        field: 'calendar',
        fieldid: 105,
        title: this.translate.instant('UPDATE_DATE')
      },
      event: row,
      translucent: true
    });
    return await popover.present();
  }

  async dismissClick() {
    await this.popoverController.dismiss();
  }
}
