import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, RoutesRecognized } from '@angular/router';
import { NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { filter, pairwise } from 'rxjs/operators';
import { MyusersPage } from 'src/app/myusers/myusers.page';
import { ArtechService } from 'src/app/services/artech.service';
import { TabMenuService } from '../tab-menu/tab-menu.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() title = '';
  @Input() backArrow = false;

  @Input() usersIcon = true;
  @Input() homeIcon = true;
  @Input() calendarIcon = true;

  private previousUrl = '';

  public showUsers = false;

  public selectedRow: number = null;

  public userInfo;
  public mainUser;
  constructor(
    public navCtrl: NavController,
    public artechService: ArtechService,
    private router: Router,
    private tabMenuService: TabMenuService,
    public translate: TranslateService,
    public dialog: MatDialog
  ) {
    this.router.events
      .pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
      .subscribe((events: RoutesRecognized[]) => {
        const url = events[1].urlAfterRedirects.split('/');
        let curentPage = decodeURI(url[url.length - 1]);
        this.tabMenuService.setActiveTabUpdate(curentPage);
        if ( curentPage.length > 0) {
          this.setHeaderTitle(curentPage);
        }

    });
    this.artechService.onGetUserInfo.subscribe((v: any) => {
      if (v) {
        this.userInfo = v;
      }
    })

    this.mainUser = JSON.parse(localStorage.getItem('mainUser'));
  }

  ngOnInit() { }

  setHeaderTitle(curentPage: string) {
    let translate = curentPage.toUpperCase();

    this.translate.get(translate).subscribe(translate => {
      this.title = translate;
    });

    curentPage === 'dashboard' ? this.backArrow = false : this.backArrow = true;
  }

  async openPage(page) {
    this.navCtrl.navigateForward(page);
    let translations = await this.translate.get([
      'CALENDAR',
    ]).toPromise();
    this.title = translations.CALENDAR;
    this.backArrow = true;
  }

  goHome() {
    const myUserId = this.artechService.myUserInfo.id;

    this.artechService.setUser(myUserId).then(() => {
      this.navCtrl.navigateRoot('home');
    });
  }

  handleShowUsersRow(index) {
    if (index !== this.selectedRow) {
      this.selectedRow = index;
    } else {
      this.selectedRow = null;
    }
  }

  goBack() {
    this.navCtrl.back();
  }

  openMyUsers(): void {
    const dialogRef = this.dialog.open(MyusersPage, {
      width: '250px',
      data: { name: 'Name' },
      panelClass: 'my-users-dialog',
      backdropClass: 'no-backdrop',
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
    });
  }

}
