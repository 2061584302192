import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import { TranslateModule } from '@ngx-translate/core';
import { ClickOutsideDirective } from '../../shared/directives/click-outside.directive';
import { MatDialogModule } from '@angular/material/dialog';
import { MyusersPageModule } from 'src/app/myusers/myusers.module';
import { IonicModule } from '@ionic/angular';



@NgModule({
  declarations: [ HeaderComponent, ClickOutsideDirective ],
    imports: [
        CommonModule,
        TranslateModule,
        MyusersPageModule,
        MatDialogModule,
        IonicModule
    ],
  exports: [ HeaderComponent, ClickOutsideDirective ]
})
export class HeaderModule { }
